<template>
	<MhView :isLoading="isLoading">
		
		<template v-if="$root.isSmallDevice">		
			<MobilePageTitle></MobilePageTitle>		
		</template>		
			
		<div class="border border--bottom row" v-if="$root.isSmallDevice">
			<div class="viewTitle vSpace vSpace--default hSpace hSpace--big col">
				<h2 v-html="$root.getTranslatedValue('Team')"></h2>
			</div>
		</div>	
			
		<template v-for="(item, index) in groupedPosts">
			
			<div class="border border--bottom row" v-if="_.isString(item)" :key="index">
				<div class="hSpace hSpace--big hSpace--notRight vSpace vSpace--default col col--11of12">
					<span class="font font--sizeSmall font--uppercase">
						{{$root.getTranslatedValue(item)}}
					</span>
				</div>				
				<div class="hSpace hSpace--big hSpace--notLeft  vSpace vSpace--default col col--1of12">
					<a class="toggleContent__btn" :class="{'toggleContent__btn--isActive' : itemIsUnfolded( index+1 ) }" @click="toggleContentSiblings( index+1 )"></a>
				</div>				
			</div>
			
			<div class="noFlexGrow border border--bottom row" :class="{'toggleContent__content' : item.isFolded }" :id="item.slug" v-else :key="index">
				<div class="col col--3of12 col--sm-12of12" :class="{'border border--right' : !$root.isSmallDevice}">
					<MhImage :imageObject="item.acf.image" v-if="item.acf.image"></MhImage>
				</div>
				<div class="hSpace hSpace--medium vSpace vSpace--default col col--6of12 col--sm-12of12" :class="{'border border--right' : !$root.isSmallDevice}">
					<h3 class="font font--sizeBig" v-html="$root.getTranslatedAcfValue( item.acf, 'postTitle')"></h3>
					<br />
					<div class="" v-html="$root.getTranslatedAcfValue( item.acf, 'text')"></div>
				</div>
				<div class="hSpace hSpace--medium vSpace vSpace--default col col--3of12 col--sm-12of12">
					<AsidePairs :post="item"></AsidePairs>
				</div>
				
			</div>			
			
		</template>	
	
	</MhView>
</template>

<script>
	// @ is an alias to /src
	import MhView from '@/components/MhView/MhView.vue'
	import MhImage from '@/components/MhImage/MhImage.vue'
	import AsidePairs from '@/components/AsidePairs.vue'
	import RestHandler from '@/components/RestHandler/RestHandler.js'
	import MobilePageTitle from '@/components/MobilePageTitle.vue'

	export default {
		name: 'teamView',
		components: {
			MhView,
			MhImage,
			AsidePairs,
			MobilePageTitle,
		},
		mixins: [ RestHandler ],
		props: {},
		data() {
			return {
				isLoading: true,
				posts: [],
				groupedPosts: [],
			}
		},
		methods: {
			itemIsUnfolded( itemIndex ){
				const item = this.groupedPosts[ itemIndex ]
				
				return item.isFolded ? false : true
			},
			getPostById( id ) {
				const posts = this.posts				
				return this._.findLast(posts, {id})
			},
			generateGroupedPosts() {
				let posts = []
				let latestFunction = null
				
				this._.forEach(this.posts, (post, index) => {
					let currentFunction = post.acf.function
					
					if(currentFunction != latestFunction){
						posts.push(currentFunction)						
					}
					
					latestFunction = currentFunction
					posts.push(post)
				})
				
				this.groupedPosts = posts		
			},
			toggleContentSiblings ( startIndex ) {	// get all siblings for the group and toggle the folding
				let groupedPosts = this.groupedPosts
				let groupedPost
				let doToggle = true
				
				for( let i = startIndex; i < groupedPosts.length; i++ ) {
					groupedPost = groupedPosts[i]
					
					if( this._.isObject( groupedPost ) && doToggle ){
						groupedPost.isFolded = !groupedPost.isFolded
					}
					// stop walk if the end of the group is reached
					else{
						doToggle = false
					}
				}
				
			},
			getImageContainerStyles( post ) {
				if( post && post.acf && post.acf.image ){
					const width = post.acf.image.width
					const height = post.acf.image.height
					const imageSrc = post.acf.image.sizes.medium
					
					return {
						paddingBottom: (height/width) * 100 + '%',
						backgroundImage: 'url('+imageSrc+')',
					}			
				}
			},
		},
		mounted() {
			
			// fetch teamPosts
			/*
			this.restHandler__fetch({
				action : 'GET',	
				route : '/wp-json/wp/v2/teamPosts',
				params : {
					per_page : 99,
				},
				callback : (response) => {
					this.posts = response.data
					this.isLoading = false
					
					// add new prop to handle post folding on mobile
					this._.map(this.posts, (post) => {
						this.$set( post, 'isFolded', true )
					});					
					
					// generate array with group title and objects
					this.generateGroupedPosts()
					
					// wenn man von der suche kommt ist der slug mit in der url,
					// und zu dem soll gleich gescrollt werden					
					if( this.$route.params.postSlug ){
						setTimeout( () => {
							const element = document.getElementById( this.$route.params.postSlug );
							element.scrollIntoView( {block: "start", behavior: "smooth"} )
						}, 150)
					}
					
				},
			})
			*/	
			
			// fetch all teamPosts
			this.restHandler__fetchAllPaginatedPosts({
				action : 'GET',				
				route : '/wp-json/mh/v1/posts',
				params : {
					postType : 'team_post',
					perPage : 50,
				},
				callback : (results) => {
					this.posts = results
					this.isLoading = false
					
					// add new prop to handle post folding on mobile
					this._.map(this.posts, (post) => {
						this.$set( post, 'isFolded', true )
					});					
					
					// generate array with group title and objects
					this.generateGroupedPosts()
					
					// wenn man von der suche kommt ist der slug mit in der url,
					// und zu dem soll gleich gescrollt werden					
					if( this.$route.params.postSlug ){
						setTimeout( () => {
							const element = document.getElementById( this.$route.params.postSlug );
							element.scrollIntoView( {block: "start", behavior: "smooth"} )
						}, 150)
					}
				}				
			})					
			
		},
	}
</script>

<style lang="less" scoped>
</style>
